/*global angular */

/**
 * The main twitter app module
 *
 * @type {angular.Module}
 */

angular = require('angular');
require('angular-route');
require('angular-sanitize');
// require('angular-ui-bootstrap');
// require('video.js');
// require('vjs-video');
// require('angular-hashtagify');
MobileDetect = require('mobile-detect');
require('../dist/templateCacheViews');

angular.module('facebook', ['ngRoute','ngSanitize','facebookViews'/*,'ui.bootstrap','vjs.video','hashtagify'*/])
	.config(function ($routeProvider,$locationProvider) {
		'use strict';
		$locationProvider.hashPrefix('');
		$locationProvider.html5Mode({
		  enabled: true,
		  requireBase: false
		});
		var routeConfig = {
			controller: 'FacebookCtrl',
			templateUrl: '/views/index.html'
		};

		$routeProvider
			.when('/', routeConfig)
			.otherwise({
				redirectTo: '/'
			});
	})
	.constant('API_CONFIG', {
	    'url':  'http://api.eyeseetest.com',
			// 'url':  'http://localhost/api/public',
	    'assets':  ''
	})

require('facebookCtrl');
