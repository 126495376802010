angular = require("angular");

angular.module("facebook").controller("FacebookCtrl", [
  "$rootScope",
  "$scope",
  "$http",
  "API_CONFIG",
  "$location",
  "$window",
  "$document",
  "$sce" /*, '$uibModal'*/,
  "$timeout",
  "$interval",
  function FacebookCtrl(
    $rootScope,
    $scope,
    $http,
    API_CONFIG,
    $location,
    $window,
    $document,
    $sce /*, $uibModal*/,
    $timeout,
    $interval
  ) {
    "use strict";
    var md = new MobileDetect($window.navigator.userAgent);
    if (!md.mobile()) {
      alert("Only mobile!");
      return;
    }
    var videoIDS = [];
    var location = $location.search();
    $scope.location = location;

    // $scope.textPost={};
    // $scope.textPost.type='text';
    // $scope.textPost.profileImage="public/images/profile-img.png";
    // $scope.textPost.headline="<b>Paja patak</b> is with <b>Ime Prezime</b> and <b>Ime Prezime</b>";
    // $scope.textPost.worldTxt="2 mins";
    // $scope.textPost.txt="Lorem ipsum dolor sit amet, consectetur adipiscing elit <b>#loremipsum</b>";
    // $scope.textPost.reactionLike=true;
    // $scope.textPost.reactionLove=true;
    // $scope.textPost.reactionHaha=true;
    // $scope.textPost.reactionWow=true;
    // $scope.textPost.reactionSad=true;
    // $scope.textPost.reactionAngry=true;
    // $scope.textPost.reactions="5.1k";
    // $scope.textPost.comments="119 Comments";
    // $scope.textPost.shares="4 Shares";

    $http({
      method: "GET",
      url: API_CONFIG.url + "/facebook/timeline?" + "id=" + location.timeline,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then(
      function (data, status) {
        if (data.data.id) {
          $scope.nextPage = data.data.nextPage;
          $scope.searchLabel = data.data.searchLabel;
          $rootScope.likeLabel = data.data.likeLabel;
          $rootScope.commentLabel = data.data.commentLabel;
          $rootScope.shareLabel = data.data.shareLabel;
          $scope.posts = angular.fromJson(data.data.posts);

          $rootScope.ctaClicked = [];
          console.log($scope.posts);
          var i = 0;
          var allPosts = [];
          angular.forEach($scope.posts, function (post) {
            post.data = angular.fromJson(post.data);
            allPosts.push({
              selector: "#" + post.name + "-" + i,
              name: post.name + "-" + i,
            });
            if (post.type == "video" || post.type == "image") {
              allPosts.push({
                selector: "#" + post.name + "Modal",
                name: post.name + "Modal",
              });
            }
            i++;
          });
          $timeout($scope.setCounters, 2000, false, allPosts);
          $rootScope.currTime = 0;
          $interval($scope.updateTime, 100);
        } else {
          alert("No active");
        }
      },
      function (data, status) {
        alert("No timeline");
      }
    );

    $scope.setCarousel = function () {
      $timeout($scope.setCarousell, 500);
    };

    $scope.setCarousell = function () {
      $(".post-carousel").slick({
        dots: false,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: false,
        infinite: false,
      });
    };

    $scope.updateTime = function () {
      $rootScope.currTime += 0.1;
      $rootScope.currTime = Math.round($rootScope.currTime * 100) / 100;
    };

    $scope.setCounters = function (allPosts) {
      console.log(allPosts);
      $.counter({
        fields: allPosts,
        reportInterval: 1,
        percentOnScreen: "90%",
        callback: function (data, log) {
          angular.forEach(log, function (value, key) {
            if (key.indexOf("-startTime") == -1) {
              if (value == 0.3) {
                log[key + "-startTime"] = $rootScope.currTime - value;
                log[key + "-startTime"] =
                  Math.round(log[key + "-startTime"] * 100) / 100;
              }
              if (value > 0.3 && !log.hasOwnProperty(key + "-startTime")) {
                log[key + "-startTime"] = $rootScope.currTime - value;
                log[key + "-startTime"] =
                  Math.round(log[key + "-startTime"] * 100) / 100;
              }
            }
          });
          $scope.statistics = log;
          console.log(log, $rootScope.currTime);
        },
      });

      $(".modal").on("hidden.bs.modal", function (e) {
        var video = $(e.delegateTarget).find("video");
        console.log(video.get(0));
        if (typeof video.get(0) !== "undefined") {
          video.get(0).pause();
          video.currentTime = 0;
          video.load();
        }
      });
    };

    $window.history.pushState(null, "any", $location.absUrl());
    $window.history.pushState(null, "any", $location.absUrl());
    $window.history.pushState(null, "any", $location.absUrl());
    $window.history.pushState(null, "any", $location.absUrl());
    $window.history.pushState(null, "any", $location.absUrl());
    $window.history.pushState(null, "any", $location.absUrl());
    $window.history.pushState(null, "any", $location.absUrl());
    $window.history.pushState(null, "any", $location.absUrl());
    $window.history.pushState(null, "any", $location.absUrl());
    $window.history.pushState(null, "any", $location.absUrl());

    $scope.gotoNextPage = function () {
      //console.log('-----------------',$scope.statistics);

      var dataForSending = {
        id: location.timeline,
        statistics: $scope.statistics,
        totalTime: $rootScope.currTime,
        cta: $rootScope.ctaClicked,
      };
      $http({
        method: "POST",
        url: API_CONFIG.url + "/tests/facebook/save",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data:
          "data=" +
          angular.toJson(dataForSending) +
          "&id=" +
          location.id +
          "&userid=" +
          location.userid +
          "&timeline=" +
          location.timeline,
      }).then(
        function (data, status) {
          $window.location.href = data.data;
        },
        function (data, status) {
          alert("Error");
        }
      );
    };

    $scope.onCtaClick = function (post) {
      if (!$rootScope.ctaClicked.includes(post.name + "-" + post.idIndex)) {
        $rootScope.ctaClicked.push(post.name + "-" + post.idIndex);
      }
    };
  },
]);
